import { Separator } from "./components/ui/separator";

interface PenaltiesProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const tiers = [
  {
    amount: 40,
    dsTier: "-",
    innerTier: "Fight Club",
  },
  {
    amount: 80,
    dsTier: "Bronze",
    innerTier: "Fight Club",
  },
  {
    amount: 200,
    dsTier: "Silver",
    innerTier: "Fight Club",
  },
  {
    amount: 400,
    dsTier: "Gold",
    innerTier: "Fight Club",
  },
  {
    amount: 800,
    dsTier: "Platinum",
    innerTier: "Beach Club",
  },
  {
    amount: 4000,
    dsTier: "Champion",
    innerTier: "Beach Club",
  },
  {
    amount: 10000,
    dsTier: "Champion",
    innerTier: "Gentlement Club",
  },
  {
    amount: 40000,
    dsTier: "Champion",
    innerTier: "Diamond Club",
  },
];

const Modal = (props: PenaltiesProps & { children: React.ReactNode }) => {
  const { open, setOpen, children } = props;

  return (
    <div
      className={`fixed inset-0 z-50 overflow-y-auto ${
        open ? "block" : "hidden"
      }`}
    >
      <div className="flex items-center justify-center min-h-screen">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-40 transition-opacity blur-xl"
          onClick={() => setOpen(false)}
        ></div>
        <div className="relative rounded-lg w-[30rem] bg-[#222831] p-6">
          {children}
        </div>
      </div>
    </div>
  );
};

export const TiersModal = (props: PenaltiesProps) => {
  const { open, setOpen } = props;

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-row justify-between">
          <h2 className="text-xl font-semibold">Tiers</h2>
          <button onClick={() => setOpen(false)}>
            <svg
              className="w-6 h-6 text-gray-400 hover:text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-row justify-between">
            <p className="font-semibold">Amount</p>
            <p className="font-semibold">DuckSTARTER</p>
            <p className="font-semibold">Inner Circle</p>
          </div>
          {tiers.map((tiers, index) => (
            <div key={index} className="flex flex-row justify-between">
              <p>{tiers.amount.toLocaleString()} DD</p>
              <p className="font-thin text-sm">{tiers.dsTier}</p>
              <p className="font-thin text-sm">{tiers.innerTier}</p>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};
