import { Separator } from "./components/ui/separator";

interface PenaltiesProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const penalties = [
  {
    days: 'less than 10 days ago',
    penalty: 30,
  },
  {
    days: 'less than 20 days ago',
    penalty: 25,
  },
  {
    days: 'less than 30 days ago',
    penalty: 20,
  },
  {
    days: 'less than 60 days ago',
    penalty: 10,
  },
  {
    days: 'less than 90 days ago',
    penalty: 5,
  },
  {
    days: 'after 90 days',
    penalty: 0,
  },
]

const Modal = (props: PenaltiesProps & { children: React.ReactNode }) => {
  const { open, setOpen, children } = props;

  return (
    <div className={`fixed inset-0 z-50 overflow-y-auto ${open ? 'block' : 'hidden'}`}>
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-40 transition-opacity blur-xl" onClick={() => setOpen(false)}></div>
        <div className="relative rounded-lg w-96 bg-[#222831] p-6">
          {children}
        </div>
      </div>
    </div>
  )
}

export const PenaltiesModal = (props: PenaltiesProps) => {
  const { open, setOpen } = props;

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-row justify-between">
          <h2 className="text-xl font-semibold">Penalties</h2>
          <button onClick={() => setOpen(false)}>
            <svg className="w-6 h-6 text-gray-400 hover:text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-row justify-between">
            <p className="font-semibold">Days</p>
            <p className="font-semibold">Penalty</p>
          </div>
          {penalties.map((penalty, index) => (
            <div key={index} className="flex flex-row justify-between">
              <p>{penalty.days}</p>
              <p>{penalty.penalty}%</p>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}
