import { default as AccessAbi } from './access.json';
import { default as TokenAbi } from './token.json';

export const Addresses = {
  token: '0x7d51888C5ABb7CDfa9cdd6A50673c7F8afAccD7F',
  access: '0x8294f417f85e5AeFC6ffe62EAc6956a8B4950Bd7',
};

export const Access: any = {
  address: Addresses.access,
  abi: AccessAbi,
};

export const Token: any = {
  address: Addresses.token,
  abi: TokenAbi,
};

export {AccessAbi, TokenAbi}
