import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { WagmiConfig, createConfig, configureChains, mainnet, sepolia } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { ConnectKitProvider } from 'connectkit';
import { getDefaultConfig } from 'connectkit'

import './global.css';

const walletConnectProjectId = '963845f9012c5c20580c5c770c4ef630'

export const config = createConfig(
  getDefaultConfig({
    chains: [mainnet],
    autoConnect: true,
    appName: 'DuckDAO Access',
    walletConnectProjectId,
  })
)

// const client = createClient(
//   getDefaultClient({
//     appName: 'DuckDAO Access',
//     //infuraId: process.env.REACT_APP_INFURA_ID,
//     //alchemyId:  process.env.REACT_APP_ALCHEMY_ID,
//     chains: [chain.mainnet, {
//       name: 'Localhost',
//       id: 31337,
//       network: 'localhost',
//       rpcUrls: {
//         default: 'http://localhost:8545',
//       },
//     }]
//   })
// );

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <WagmiConfig config={config}>
      <ConnectKitProvider theme="auto">
        <App />
      </ConnectKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
