import { ConnectKitButton } from 'connectkit';
import { useAccount } from 'wagmi';

import styled from 'styled-components';
import { Access } from './Access';

const theme = {
  bg: '#222831',
  bg2: '#393E46',

  color: '#EEEEEE',
  color2: '#B6BBC4',
  primary: '#00ADB5',
};

const AppContainer = styled.div`
  background-color: ${theme.bg};
  color: ${theme.color};
  /* height: 100vh; */
`;

const Header = styled.header`
  // position: fixed;
  /* top: 0; */
  /* left: 0; */
  width: 100%;

  background-color: ${theme.bg2};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;

  height: 60px;
`;

const CenterBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

function App() {
  const { isConnected } = useAccount();

  return (
    <AppContainer className="dark">
      <Header>
        <img src="https://duckdao.io/wp-content/uploads/2023/01/DuckDAO-Logo-24060.png" alt="DuckDAO Logo" className="pl-[30px]" />
        <div className="pr-[30px]">
          <ConnectKitButton />
        </div>
      </Header>
      <CenterBody>
        {isConnected ? <Access /> : (
          <div>
            <h2>Please connect your wallet to use the application.</h2>
          </div>
        )}
      </CenterBody>
    </AppContainer>
  );
}

export default App;
